* {
  padding: 0;
  margin: 0;
  border: 0;
}

html,
body {
  background: #a22 url("./images/wallpaper2.png") repeat;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: white;
}

h2 {
  margin-bottom: 16px;
  font-family: "Sigmar One", cursive;
}

div,
p {
  font-size: 18px;
  font-family: Georgia, Cambria, "Times New Roman", Times, serif;
  color: rgba(0, 0, 0, 0.84);
  letter-spacing: -0.004em;
  line-height: 1.58;
}

p {
  margin-top: 1.56em;
  margin-bottom: -0.46em;
  text-indent: 24px;
}

.custom-control {
  padding: 8px;
}

.choice label {
  font-family: "Patrick Hand SC", cursive;
  font-size: 32px;
  text-shadow: 4px 4px 0 rgba(0, 0, 0, 0.26);
  color: white;
  font-weight: bold;
}

.custom-control-label {
  display: flex;
  align-items: center;
}

.custom-control-label::before {
  position: static;
  margin-right: 16px;
  flex-shrink: 0;
  margin-left: 0;
}

.day-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.content-card {
  margin: 32px;
  max-width: 480px;
  background: white;
  box-shadow: 16px 16px 0px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  padding: 32px;
  padding-bottom: 32px;
}

.tagline {
  font-family: "Sigmar One", cursive;
  font-size: 32px;
  color: white;
  background: #49007d;
  padding: 8px 16px;
  border-radius: 8px;
  margin-top: 16px;
  box-shadow: 16px 16px 0px #04cca1;
}

.choose-selector {
  max-width: 480px;
  margin: 32px;
}

.choose-selector .choice {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #45000f;
  box-shadow: 16px 16px 0px #c76374;
  font-family: "Patrick Hand SC", cursive;
  font-weight: bold;
  padding: 8px;
  font-size: 24px;
  margin: 16px;
  margin-bottom: 32px;
  max-width: 480px;
  border-radius: 16px;
}

.choose-selector .choice.active {
  background: #fab411;
  box-shadow: 16px 16px 0px #ffe099;
}

.inventory {
  border: 2px solid rgba(0, 0, 0, 0.84);
  border-radius: 16px;
  padding: 32px;
  padding-top: 16px;
  margin-top: 64px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.inventory-label {
  position: absolute;
  font-family: "Patrick Hand SC";
  font-size: 32px;
  top: 0;
  left: 32px;
  margin-top: -30px;
  background: white;
  padding: 2px 8px;
}

.inventory-item {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  width: 80px;
  align-items: center;
}

.inventory-item-label {
  text-align: center;
}

.inventory-item-image {
  width: 100%;
  height: 100%;
  background: transparent no-repeat center / cover;
}
.inventory-item-image-wrapper {
  width: 64px;
  height: 64px;
  padding: 16px;
  background: #eaeaea;
  border-radius: 8px;
}

@keyframes crazyshakeanimation {
  0% {
    transform: translate(1.5px, 1px) rotate(0.4deg);
  }
  20% {
    transform: translate(-2px 1px) rotate(-0.8deg);
  }
  40% {
    transform: translate(0.5px, -2px) rotate(0.8deg);
  }
  60% {
    transform: translate(-1px 1.5px) rotate(-0.4deg);
  }
  80% {
    transform: translate(-1.5px 2px) rotate(0.4deg);
  }
  100% {
    transform: translate(0.5px 1px) rotate(0deg);
  }
}

.crazyshake {
  animation: crazyshakeanimation 0.01s infinite;
}

.click-reveal div {
  margin-top: 32px;
  margin-bottom: 32px;
}

.click-reveal div.text {
  display: block;
}

button {
  cursor: hover;
}

span.censored {
  background: black;
  padding: 2px 8px;
}
